import gql from 'graphql-tag'

export const TaskUpdateFragment = gql`
  fragment TaskUpdate on Task {
    name
    execConditions
    executionTimeout
    maxMemoryMB
    script
  }
`

export const TaskCreateFragment = gql`
  fragment TaskCreate on Task {
    _id
    environmentId
    createdAt
    status
    ephemeral
    parentTask
    statusLog {
      timestamp
      status
      reason
      childTaskId
    }
    lastExecutionTime
    lastExecutionLog {
      t
      m
    }
  }
`

export const TaskFragment = gql`
  fragment Task on Task {
    ...TaskCreate
    ...TaskUpdate
  }

  ${TaskCreateFragment}
  ${TaskUpdateFragment}
`
