










































































import { Vue, Component, Prop, Watch } from 'vue-property-decorator'
import gql from 'graphql-tag'
import FuzzySearch from 'fuzzy-search'
import { Task, PaginatedQueryResult, TaskStatus } from '@/models'
import moment from '@/plugins/moment'

@Component({
  apollo: {
    tasks: {
      query() {
        return gql`
          query getTasks($environmentId: ID) {
            tasks(environmentId: $environmentId) {
              items {
                _id
                name
                lastExecutionTime
                status
              }
            }
          }
        `
      },
      variables() {
        return {
          environmentId: this.environmentId
        }
      },
      fetchPolicy: 'network-only'
    }
  }
})
export default class TaskEditorSidebar extends Vue {
  @Prop({ type: String, required: true }) environmentId!: string
  @Prop({ type: Number }) lastUpdated?: number

  tasks: Partial<PaginatedQueryResult<Task>> = {}
  searchQuery = ''

  get taskResults() {
    if (!this.tasks.items) return []
    const displayResult = this.tasks.items.map((task) => ({
      ...task,
      name: task.name,
      title: task.name,
      subtitle: this.getLastRunText(task),
      icon: {
        stopped: 'event_available',
        running: 'date_range',
        errored: 'event_busy',
        interrupted: 'event_busy'
      }[task.status]
    }))
    const searcher = new FuzzySearch(displayResult || [], [
      'name',
      'title',
      'subtitle',
      '_id'
    ])
    return searcher.search(this.searchQuery)
  }

  getLastRunText(task: Task) {
    if (!task.lastExecutionTime) return 'Nunca ejecutada'
    if (task.status === TaskStatus.Running) return 'EJECUTANDO'
    if (task.status === TaskStatus.Errored)
      return `ERROR (Ejecutada ${moment(task.lastExecutionTime).fromNow()})`
    if (task.status === TaskStatus.Interrupted)
      return `INTERRUMPIDA (Ejecutada ${moment(
        task.lastExecutionTime
      ).fromNow()})`
    return `Ejecutada ${moment(task.lastExecutionTime).fromNow()}`
  }

  @Watch('lastUpdated')
  refreshTaskList() {
    this.$apollo.queries.tasks.refresh()
  }
}
